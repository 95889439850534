<template>
    <div
        :class="type.key === 'usdt' ? 'payments__list-item payments__list-item-usdt' : 'payments__list-item'"
    >
        <span class="payments__list-item-badge">{{ type.title }}</span>
        <!--        <span-->
        <!--            v-if="type.key === 'usdt'"-->
        <!--            class="payments__list-item-badge-connected"-->
        <!--        >Connected</span>-->
        <div
            class="payments__list-item-inner"
        >
            <template v-for="{ title, value } in fields">
                <div
                    v-if="!!value"
                    :key="title"
                >
                    <p class="payments__list-item-label">
                        {{ title }}:
                    </p>
                    <div class="payments__list-item-value">
                        {{ value }}
                    </div>
                </div>
            </template>
        </div>
        <div class="payments__actions">
            <span
                class="payments__actions-btn"
                @click="showActionPopup"
            >
                <img
                    src="@/assets/img/svg/ellipsis.svg"
                    alt=""
                >
                <div
                    v-if="isShowActions"
                    v-click-outside="closeActionPopup"
                    class="payments__actions-window"
                >
                    <!-- Edit -->
                    <span
                        v-if="!notEditable"
                        class="order-action__item"
                        @click.prevent="$emit('edit')"
                    >
                        Edit
                    </span>
                    <!-- Delete -->
                    <span
                        class="order-action__item"
                        @click.prevent="$emit('delete')"
                    >
                        Delete
                    </span>
                </div>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PaymentMethodItem',
    props: {
        index: {
            type: [String, Number],
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
        type: {
            type: Object,
            required: true
        },
        notEditable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isShowActions: false
        }
    },
    methods: {
        showActionPopup() {
            this.isShowActions = true
        },
        closeActionPopup() {
            this.isShowActions = false
        }
    }
}
</script>
