<template>
    <setting-block-layout
        :loading="loading"
        :btn-hide="false"
    >
        <template slot="body">
            <div class="payments__add">
                <button
                    default
                    class="btn-base btn-main button payments__add-btn"
                    @click.prevent="onAddMethod"
                >
                    Add new payment
                </button>
            </div>
            <RadiusLoader v-if="loading" />
            <div
                v-else-if="!loading && connectedMethods.length > 0"
                class="payments__list"
            >
                <template
                    v-for="(method, index) in connectedMethods"
                >
                    <payment-method-item
                        :key="method.id"
                        :index="index"
                        :fields="method.fields"
                        :type="getType(method.type)"
                        @edit="onEdit(method.id, method.type)"
                        @delete="onDelete(method.id)"
                    />
                </template>
            </div>
            <no-result v-else-if="!loading && connectedMethods.length === 0" />
            <confirm-modal ref="confirm-modal" />
        </template>
    </setting-block-layout>
</template>

<script>

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import PaymentMethodItem from '@/components/account-settings/PaymentMethodItem.vue';
import ConfirmModal from '@/components/modals/ConfirmModal';
import RadiusLoader from '@/components/common/RadiusLoader.vue'

import { eventBus } from '@/helpers/event-bus/'

import {
    affiliateListAvailable,
    getPaymentsAffiliateListConnected,
    deleteWithdrawalMethod
} from '@/services/referral';

export default {
    components: {
        SettingBlockLayout,
        PaymentMethodItem,
        ConfirmModal,
        RadiusLoader
    },
    metaInfo: {
        title: 'Affiliate'
    },
    data() {
        return {
            loading: false,
            fields: null,
            types: [],
            connectedMethods: []
        }
    },
    async created() {
        await this.getTypes()
        await this.getMethods()
    },
    methods: {
        getPaymentsAffiliateListConnected,
        affiliateListAvailable,
        async getTypes() {
            const { fields, types } = await this.affiliateListAvailable()
            this.fields = fields
            this.types = types
        },
        getType(type) {
            return this.types.find(({ key }) => key === type)
        },
        async deleteWithdrawalMethod(payload) {
            try {
                await deleteWithdrawalMethod(payload)
                this.connectedMethods = this.connectedMethods.filter((item) => +item.id !== +payload.id)
                eventBus.$emit('showSnackBar', 'Withdrawal method has been deleted', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async getMethods() {
            this.loading = true
            const data = await this.getPaymentsAffiliateListConnected()
            // let arr = []
            const arr = data.list.map((item) => {
                const fields = []
                Object.keys(item).forEach((methodKey) => {
                    Object.values(this.fields[item.method]).forEach((field) => {
                        if (methodKey === field.key) {
                            fields.push({
                                title: field.title,
                                value: item[methodKey]
                            })
                        }
                    })
                })
                return {
                    type: item.method,
                    id: item.id,
                    fields
                }
            })
            this.connectedMethods = arr
            this.loading = false
        },
        onEdit(id, type) {
            this.$router.push({ name: 'affiliate-add-payment-method', params: { editId: id, type } })
        },
        onDelete(id) {
            const title = 'Delete withdrawal method'
            const message = 'Are you sure you want to remove this withdrawal method?'
            this.openConfirmModal(title, message, id)
        },
        // async onSave() {},
        openConfirmModal(title, message, id) {
            this.$refs['confirm-modal'].open(title, message)
                .then((res) => {
                    if (res) {
                        this.deleteWithdrawalMethod({ id })
                    }
                })
        },
        onAddMethod() {
            this.$router.push({ name: 'affiliate-add-payment-method' })
        }
    }
}
</script>

<style lang="scss">
.payments {
    &__container {
        display: grid;
        padding: 30px;
        padding: 15px 30px;
        @media (max-width: 768px) {
            padding: 15px 10px;
        }
        // gap: 30px;
    }
    &__empty {
        padding: 50px 0;
        font-size: 48px;
        font-weight: 700;
        margin: 0 auto;
        width: fit-content;
        max-width: 800px;
        text-align: center;
        color: #181818;
    }
    &__list {
        display: grid;
        gap: 30px;
        margin-bottom: 30px;
        &-item {
            padding: 15px 15px;
            margin: 20px 0;
            border-radius: 6px;
            box-shadow: 0px 5px 30px 0 rgb(0 0 0 / 8%);
            font-size: 18px;
            font-style: normal;
            font-stretch: normal;
            font-weight: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #181818;
            display: grid;
            grid-template-columns: 1fr min-content;
            grid-template-areas: "badge actions"
                "inner actions";
            align-items: baseline;
            row-gap: 10px;
            &-payoneer {
                grid-template-columns: max-content 1fr min-content;
                grid-template-areas: "badge connected actions";
                gap: 20px;
            }
            &-label {
                color: rgba(0, 0, 0, 0.6);
            }

            &-value {
                font-weight: bold;
            }

            // &-index {
            //     grid-area: index;
            //     min-width: 35px;
            // }
            &-badge {
                grid-area: badge;
                font-size: 14px;
                font-weight: 500;
                padding: 8px 12px;
                display: inline-block;
                border-radius: 4px;
                background: rgba($main-color, 0.1);
                color: $main-color;
                justify-self: start;

                &-connected {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 8px 12px;
                    display: inline-block;
                    border-radius: 4px;
                    justify-self: start;
                    background: rgba(89, 199, 77, 0.1);
                    color: #59c74d;
                }

                &.payoneer-status {
                    &--active {
                        background: rgba(89, 199, 77, 0.1);
                        color: #59c74d;
                    }
                    &--pending {
                        background: rgba(255, 105, 12, 0.1);
                        color: #ff690c;
                    }
                    &--inactive {
                        background: rgba(212, 28, 28, 0.1);
                        color: #d41c1c;
                    }
                }
            }
            &-inner {
                grid-area: inner;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                @media (max-width: 768px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    &__add {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        margin-bottom: 15px;
        &-btn {
            min-width: fit-content;
        }
    }
    &__actions {
        grid-area: actions;
        position: relative;
        align-self: flex-start;
        &-btn {
            padding: 10px;
            cursor: pointer;
            > img {
                transform: rotate(90deg);
            }
        }
        &-window {
            position: absolute;
            top: calc(100% + 5px);
            right: 0;
            display: flex;
            flex-direction: column;
            padding: 10px 25px;
            background: #181818;
            color: #fff;
            gap: 5px;
            font-weight: 500;
            border-radius: 4px;
        }
    }
    &-add {
        &__container {
            display: grid;
            padding: 30px;
            gap: 30px;
            @media (max-width: 576px) {
                padding: 15px 10px;
            }
            .profile-info__footer{
                justify-self: center;
                gap: 30px;
                min-width: 350px;
            }
        }
        &__select {
            width: auto !important;
            justify-self: start;
        }
    }
}
</style>
